import * as React from "react";

type Props = {
  src: string;
};

// todo: video loading

export const VideoFrame = (props: Props) => {
  return (
    <video
      style={{
        width: "100%",
        display: "block",
        minHeight: 330,
      }}
      src={props.src}
      muted
      autoPlay
      loop
      playsInline
    />
  );
};
