import * as React from "react";
import dedent from "dedent";

import { Frame } from "../components/Frame/Frame";
import { VideoFrame } from "../VideoFrame";
import {
  BeliefsContent,
  BeliefsHeading,
} from "../components/Typography/Typography";
import { Container } from "../components/Container";
import Layout from "../components/Layout";

const belief1 = {
  title: dedent`
      1
      create
    `,
  body: dedent`
      express yourself, for there is no better way to feel alive.
      the sights & sounds of your imagination inspire others. 
      creativity provides a spiritual jolt—in order to heal & unite.
    `,
};
const belief2 = {
  title: dedent`
      2
      together
  `,
  body: dedent`
      show the kids that the world is their canvas—then give them the brushes & the palette.
      share your stories, color outside the lines & dance to your own rhythms.
  `,
};
const belief3 = {
  title: dedent`
      3
      from & for the heart
    `,
  body: dedent`
      the world needs something real.
      the world needs something original.
      the world needs you.
    `,
};

export const BeliefsPage = () => {
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <Container>
          <div
            style={{
              marginTop: 55,
            }}
          >
            <Frame
              firstBackground="#FFB053"
              secondBackground="#FF7918"
              showSoundOnButton
            >
              <VideoFrame src="/videos/beliefs (houston).mp4" />
            </Frame>
          </div>
        </Container>

        <div
          style={{
            marginTop: 28,
          }}
        >
          <div style={{ marginBottom: 24 }}>
            <BeliefsHeading style={{ fontFamily: "editorial" }}>
              {joinWith(belief1.title.split("\n"), <br />)}
            </BeliefsHeading>

            <BeliefsContent
              style={{ whiteSpace: "normal", padding: "0px 10px" }}
            >
              {joinWith(belief1.body.split("\n"), <br />)}
            </BeliefsContent>
          </div>
          <div style={{ marginBottom: 24 }}>
            <BeliefsHeading style={{ fontFamily: "editorial" }}>
              {joinWith(belief2.title.split("\n"), <br />)}
            </BeliefsHeading>

            <BeliefsContent
              style={{ whiteSpace: "normal", padding: "0px 10px" }}
            >
              the culture should be led by the{" "}
              <span style={{ textDecoration: "line-through" }}>people</span>{" "}
              kids.
              <br />
              {joinWith(belief2.body.split("\n"), <br />)}
            </BeliefsContent>
          </div>
          <div style={{ marginBottom: 24 }}>
            <BeliefsHeading style={{ fontFamily: "editorial" }}>
              {joinWith(belief3.title.split("\n"), <br />)}
            </BeliefsHeading>

            <BeliefsContent
              style={{ whiteSpace: "normal", padding: "0px 10px" }}
            >
              {joinWith(belief3.body.split("\n"), <br />)}
            </BeliefsContent>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BeliefsPage;

function joinWith<T>(items: T[], separator: T): T[] {
  const result = [];
  for (let i = 0; i < items.length; i++) {
    if (i !== items.length - 1) {
      result.push(items[i], separator);
    } else {
      result.push(items[i]);
    }
  }

  return result;
}
